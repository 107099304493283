import { RecFeedLayout } from 'constants/tracking';
import { getScreenSizeName } from 'utils/screen-size';

export const enum ProductsPerRow {
  MOBILE = 2,
  TABLET = 4,
  DESKTOP = 6,
}

export type CustomProductsPerRow = Record<keyof typeof ProductsPerRow, number>;

export const ProductsPerRowClassNames: Readonly<{
  MOBILE: `grid-cols-${ProductsPerRow.MOBILE}`;
  TABLET: `tablet:grid-cols-${ProductsPerRow.TABLET}`;
  DESKTOP: `desktop:grid-cols-${ProductsPerRow.DESKTOP}`;
}> = {
  MOBILE: 'grid-cols-2',
  TABLET: 'tablet:grid-cols-4',
  DESKTOP: 'desktop:grid-cols-6',
};

export const enum ProductGapPixels {
  MOBILE = 8,
  TABLET = 32,
  DESKTOP = 24,
}

export const enum ProductGapClassName {
  MOBILE = 'gap-2',
  TABLET = 'tablet:gap-8',
  DESKTOP = 'desktop:gap-6',
}

/**
 * Flex basis only applies to horizontal type `<RecommendationFeed>` components.
 *
 * It ensures a max of `ProductsPerRow.<screenSize>` items are shown while
 * taking the gap between items into consideration for sizing.
 *
 * Example 1: There are 2 products shown, the gap is 8px, the flex basis is:
 *   * (100% / `<numOfProducts>`) - (`<gapWidth>` * `<numOfGaps>` / `<numOfProducts>`)
 *   * (100% / 2) - (8px * 2 / 1)
 *
 * Example 2: There are 4 products shown, the gap is 32px, the flex basis is:
 *   * (100% / `<numOfProducts>`) - (`<gapWidth>` * `<numOfGaps>` / `<numOfProducts>`)
 *   * (100% / 4) - (32px * 3 / 4)
 *
 * 🚨 The values below cannot be both typed & compatible with Tailwind parser, so
 * please ensure to update these if e.g. `ProductsPerRow` or
 * `ProductGapClassName` enums are updated.
 */
export const enum ProductFlexBasis {
  MOBILE = 'basis-[calc((100%/2)-(8px*1/2))]',
  TABLET = 'tablet:basis-[calc((100%/4)-(32px*3/4))]',
  DESKTOP = 'desktop:basis-[calc((100%/6)-(24px*5/6))]',
}

export const getNumRecFeedItemPerRow = () => {
  const screenName = getScreenSizeName();

  switch (screenName) {
    case 'SMOBILE':
    case 'LMOBILE': {
      return ProductsPerRow.MOBILE;
    }

    case 'TABLET': {
      return ProductsPerRow.TABLET;
    }

    case 'DESKTOP': {
      return ProductsPerRow.DESKTOP;
    }
  }
};

// Get number of products per row for sponsored ads in the recommendation feed
export const getSponsoredAdsRecFeedItemRow = () => {
  const screenName = getScreenSizeName();

  switch (screenName) {
    case 'DESKTOP': {
      return ProductsPerRow.DESKTOP; // There are 6 products in each row and they appear to be in a grid (but it is not really a grid). Therefore, we calculate the SKU "y_position" (the row position of each SKU) based on this value.
    }

    default: {
      return null; // TODO: Workaround - Sponsored Ads are not currently implemented with the grid layout.
    }
  }
};

/**
 * Get layout type for the sponsored ads in the recommendation feed, based on the screen size
 * Sponsored ads are not currently implemented with the grid layout, but for desktop screens they appear like a grid with 6 SKUs in each row.
 */
export const getSponsoredAdsRecFeedLayoutType = () => {
  const screenName = getScreenSizeName();

  switch (screenName) {
    case 'DESKTOP': {
      return RecFeedLayout.GRID; // For desktop screens, the sponsored ads layout type is grid.
    }

    default: {
      return RecFeedLayout.ROW; // For other screen sizes, the sponsored ads layout type is row.
    }
  }
};
